/* Hide default input */
.toggle input {
  display: none;
}

/* The container and background */
.toggle {
  text-align: left;
  position: relative;
  display: inline-block;
  width: 80px;
  height: 30px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.4s;
}

/* The sliding button */
/* "IN" OFF Text */
.slider:before {
  position: absolute;
  content: "IN";
  width: 38px;
  height: 27px;
  text-align: center;
  top: 0;
  padding-top: 6px;
  background-color: var(--secondary-color);
  color: var(--neutral-color-dark);
  border-radius: 3px;
  transition: all 0.4s;
}

/* "MM" OFF Text*/
input:checked + .slider:before {
  transform: translateX(40px);
  padding-top: 6px;
  content: "MM";
  text-align: center;
}

/* "MM" ON Text*/
.slider:after {
  position: absolute;
  content: "MM";
  top: 6px;
  right: 6px;
  font-size: 0.9em;
}

/* "IN" ON Text */
input:checked + .slider:after {
  content: "IN";
  left: 6px;
  margin-left: 0;
}
