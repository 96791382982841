.plate {
  /* margin-top: 2054px; */
  margin-top: 0px;
  /* width: 100%;
  margin: 154px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1px;
  justify-items: center; */
}

.left {
  background-color: red;
}

.plate-size {
  width: 100%;
  margin: auto;
  background-color: lightslategrey;
}

.plate-size_inner {
  padding: 10px 10px 5px;
}

.holes {
  width: 100%;
  margin: auto;
  background-color: hsl(0, 0%, 50%);
}

.holes_inner {
  padding: 5px 10px 5px;
}

.three-col-row {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1px;
  justify-items: center;
}

.two-col-row {
  margin: 0 auto;
  display: grid;
  grid-template-columns: 65% 35%;
  grid-gap: 1px;
  justify-items: left;
}

.edit__label {
  padding: 5px 10px;
}

.edit__input {
  border: 1px solid black;
  border-radius: 5px;
  font-size: inherit;
  padding: 5px 10px;
  outline: none;
  width: 100%;
}

.radio {
  margin-right: 5px;
}

.right {
  background-color: blueviolet;
}

.top {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/* .view {
  padding: 1px;
  border: 1px solid #021a40;
  background-color: var(--neutral-color-light);
}

.view-left {
  grid-area: view-left;
}

.view-right {
  grid-area: view-right;
}

.view-tl {
  grid-area: view-tl;
}

.view-tr {
  grid-area: view-tr;
}

.view-bl {
  grid-area: view-bl;
  border-top: none;
}

.view-br {
  grid-area: view-br;
  border-top: none;
} */

.centeredTop {
  text-align: center;
  text-decoration: underline;
  /* position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.verticalLine {
  border-left: 1px solid var(--neutral-color-dark);
  height: 120%;
  margin: 0;
  padding: 0;
}
