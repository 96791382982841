table {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

table td,
table th {
  /* border: 1px solid #ddd; */
  padding: 8px;
  text-align: center;
}

table tr:nth-child(even) {
  background-color: #f2f2f2;
}

table tr:hover {
  background-color: #ddd;
}

table th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: var(--secondary-color);
  color: var(--cta-color);
}

table td input {
  width: 50px;
  background-color: rgb(238, 238, 238);
  padding: 5px;
  text-align: right;
  border-radius: 4px;
}

table td input:focus {
  background-color: white;
}

.table-footer {
  padding-top: 12px;
  padding-bottom: 12px;
  width: 85px;
}

.total {
  font-size: 120%;
}
