.legal {
    display: block;
    width: 80%;
    border: 3px solid var(--neutral-color-dark);
    padding: 50px;
    text-align: left;
    margin: 20px;
    background-color: var(--neutral-color-light);
    border-radius: 10px;
    font-size: 1em;
}

.legal>p {
    /* background-color: blueviolet; */
    padding: 10px 30px 10px 30px;
}

.legal>p:first-of-type {
    /* background-color: rgb(43, 226, 52); */
    padding-top: 35px;
  }

.legal>h1{
    font-size: 1.75em;
    padding-top: 16px;
    padding-bottom: 0px;
    /* background-color: aquamarine; */
}

.legal>h2{
    font-size: 1.5em;
    padding-top: 14px;
    padding-bottom: 14px;
    /* background-color: lightcoral; */
}

.legal>h3{
    font-size: 1.25em;
    padding-top: 12px;
    padding-bottom: 12px;
    /* background-color: royalblue; */
}

.legal>h4{
    font-size: 1.125em;
    padding-top: 10px;
    padding-bottom: 10px;
    /* background-color: teal; */
}

.legal>ul {
    /* text-indent: 3em; */
    margin-left: 3em;
    /* background-color: yellow; */
}

.legal>ul>* {
    margin-left: 1em;
    list-style-type: circle;
    /* background-color: pink; */
}