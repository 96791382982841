/* @import-normalize; */
@import "./fonts/font.css";
@import "./onshape/components/PlateForm/PlateForm.css";
/* var bg = require("../onshape/components/img/chamfer-holes.jpg"); */

*,
*::before,
*::after {
  /* width: 100%;
  height: 100%; */
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  font-family: "Poppins-Medium", sans-serif;
  box-sizing: border-box;
}

/* https://colorpalettes.net/color-palette-2664/
dark green #4a6c2f  hsl(93, 39%, 30%)
light green #7cad3e  hsl(86, 47%, 46%) [ugly]
light blue/green #cfdfda hsl(161, 20%, 84%)
dark blue #236477 hsl(194, 55%, 30%)
very dark blue #173042 hsl(205, 48%, 17%) */

/* https://websitesetup.org/website-color-schemes/
--Primary colors go to the “hot spots” on your web page. You should use these bold,
  vibrant colors to attract users’ attention and prompt them to take action. CTA
  buttons, headlines, benefits icons, download forms, and other important information
  should be highlighted using primary colors.
  --branding-color: hsl(93, 39%, 30%)
  --cta-color: hsl(194, 55%, 30%)
--Secondary colors are used to highlight the less important information on the
  website, such as secondary buttons, subheadings, active menu items, backgrounds, or
  supporting content like FAQs and testimonials.
  --secondary-color: hsl(161, 20%, 84%)
--Neutral colors will most likely be used for text and background but could come in
  handy in particularly colorful sections of the site, just to help tone it down and
  refocus the eye.
  --neutral-color-light: hsl(180, 16.7%, 97.6%);
  --neutral-color-dark: hsl(205, 48%, 17%);
  */

html {
  /* cta */
  --branding-color: hsl(93, 39%, 30%);
  --cta-color: hsl(194, 55%, 30%);
  /* secondary */
  --secondary-color: hsl(161, 20%, 84%);
  --btn-color-activated: hsl(161, 20%, 54%);
  /* neutral */
  --neutral-color-light: hsl(180, 16.7%, 97.6%);
  --neutral-color-dark: hsl(205, 48%, 17%);
  /* disabled */
  --disabled-color: hsl(0, 0%, 67%);

  --nav-height: 54px;
  --panel-height: 41px;
}

body,
#root {
  height: 100vh;
  /* min-width: 25px; */
  text-align: center;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-color);
  color: var(--cta-color);
  padding: 0.5rem 1rem;
  /* height: 100%; */
}

.main {
  display: flex;
  flex: 1;
}

#google-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%
}

.center-vert {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100px; */
}

@media all and (max-width: 650px) {
  .main {
    flex-direction: column;
  }

  .cce {
    display: none;
  }
}

.hidden {
  visibility: hidden;
}

.unit {
  font-size: 0.65em;
  color: #1f1f1f;
  padding: 3px;
}

.disabled {
  color: rgb(170, 170, 170);
}

.background-image {
  background-image: url(./onshape/components/img/chamfer-holes.jpg);
  background-size: cover;
}

.data-view {
  flex: 1 1 5rem;
  min-width: 320px;
}

.model-view {
  padding-top: 2rem;
  flex: 3 3 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.viewport {
  /* border: 1px dashed; */
  width: 100%;
  flex: 1;
  min-width: 300px;
  min-height: 300px;
}

.control-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--neutral-color-dark);
  padding: 0.5rem 1rem;
  min-height: 150px;
  width: 100%;
}

.instructions {
  align-self: center;
  font-size: 65%;
  color: gray;
  text-align: left;
}

.dot-list {
  text-align: left;
}

ul.dot-list>li {
  display: list-item;
  list-style-type: disc;
}

.threeD-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-left: 10px;
  width: 100%;
}

.ortho-view-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
}




.info {
  /* margin: auto; */
  width: 95%;
  border: 3px solid var(--neutral-color-dark);
  padding: 10px;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
  background-color: var(--neutral-color-light);
  border-radius: 10px;
}

.info-signin {
  width: fit-content;
}

.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary-color);
  color: var(--cta-color);
  padding: 0.5rem 1rem;
}

.footer-help-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
}

.footer-help-buttons>li {
  padding-left: 30px;
}

.footer-help-buttons>li:last-child {
  margin-left: auto;
  padding-right: 30px;
}

.logo-title {
  display: flex;
  justify-content: left;
  align-items: center;
}

.logo {
  width: 25px;
  border: none;
}

.cce {
  justify-self: left;
  margin-left: 10px;
  font-family: "Poppins-Bold";
  font-size: large;
}

.branding {
  color: var(--branding-color);
}

.cta {
  color: var(--cta-color);
}

.page-name {
  justify-self: left;
  margin-left: 10px;
  font-family: "Poppins-Bold";
  font-size: x-large;
  color: var(--neutral-color-dark);
}

.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.nav-link {
  text-decoration: none;
  margin-left: 1rem;
  padding: 3px;
  border-radius: 3px;
  /* background-color: red; */
  transition: color 100ms;
}

.nav-link:hover {
  background-color: var(--btn-color-activated);
  border-color: var(--neutral-color-light);
  box-shadow: 0 0 5px 2px var(--neutral-color-dark);
}

.nav-active {
  /* background-color: var(--btn-color-activated); */
  text-decoration: underline;
  font-weight: bold;
  color: var(--cta-color);
}

.panel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.panel-btn {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.panel-btn-space-between {
  justify-content: space-between;
}

.panel-btn-end {
  justify-content: flex-end;
}

.panel-btn-vert {
  display: flex;
  flex-direction: column;
}

.btn {
  background-color: var(--secondary-color);
  color: var(--neutral-color-dark);
  border: 1px;
  border-style: solid;
  border-color: black;
  outline: none;
  border-radius: 3px;
  padding: 5px 5px;
  margin: 5px;
  cursor: pointer;
  font-size: 14px;
}

.btn:disabled {
  background-color: var(--neutral-color-light);
  color: var(--disabled-color);
  cursor: inherit;
}

.btn:disabled:hover {
  background-color: var(--neutral-color-light);
  color: var(--disabled-color);
  border-color: var(--neutral-color-light);
  border: 1px;
  border-style: solid;
  border-color: black;
  box-shadow: none;
}

.btn-action {
  color: var(--cta-color);
  border: 2px;
  border-style: solid;
  margin-left: 30px;
  font-size: 120%;
  padding: 5px;
}

.btn-action-icon {
  margin-left: 5px;
}

.btn-activated {
  background-color: var(--btn-color-activated);
  color: var(--secondary-color);
}

.btn:hover {
  background-color: var(--btn-color-activated);
  border-color: var(--neutral-color-light);
  box-shadow: 0 0 5px 2px var(--neutral-color-dark);
}

.btn-login {
  background-color: var(--cta-color);
  color: var(--neutral-color-light);
  border-radius: 5px;
  height: 40px;
  width: 100%;
}

.btn-cancel {
  background-color: inherit;
  color: var(--neutral-color-dark);
  border-radius: 5px;
  height: 40px;
}

.btn-100-width {
  width: 100%;
}

/* Hide default input */
.toggle input {
  display: none;
}

/* The container and background */
.toggle {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 30px;
}

.line-separator {
  padding-top: 20px;
  padding-bottom: 5px;
}

.line-separator:after,
.line-separator:before {
  content: "\00a0\00a0\00a0\00a0\00a0";
  text-decoration: line-through;
}

.login-icon {
  margin-right: 10px;
}

.feedback.size {
  width: 100%;
  min-height: 75px;
  max-height: 500px;
}

.partNumber {
  color: var(--cta-color);
  align-self: flex-end;
  /* text-decoration: underline; */
  font-size: 120%;
}

.space-holder-text {
  color: rgba(0, 0, 0, 0);
}