/* @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap"); */
/* @import "../css/Accordion.css"; */

*,
*::before,
*::after {
  font-size: inherit;
}

/* Style the accordion section */
.accordion__section {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  width: 100%;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  background-color: var(--neutral-color-light);
  color: var(--neutral-color-dark);
  cursor: pointer;
  padding: 15px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  transition: background-color 0.6s ease;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.accordion:hover,
.active {
  background-color: var(--neutral-color-light);
}

/* Style the accordion content title */
.accordion__title {
  display: flex;
  justify-content: space-between;
  width: 93%;
  font-size: 14px;
}

.accordion__title__error {
  color: red;
}

/* Style the accordion content title */
.accordion__subtitle {
  text-align: center;
  text-decoration: underline;
  font-size: 14px;
  background-color: #f8f8f8;
}

/* Style the accordion chevron icon */
.accordion__icon {
  margin-left: auto;
  transform: rotate(270deg);
  transition: transform 0.6s ease;
  visibility: hidden;
  /* display: none; */
}

/* Style to rotate icon when state is active */
.rotate {
  transform: rotate(90deg);
  visibility: visible;
}

/* Style the accordion content panel. Note: hidden by default */
.accordion__content {
  background-color: white;
  overflow-y: auto;
  max-height: 500px;
  overflow-x: auto;
  transition: max-height 0.6s ease;
}

/* Style the accordion content text */
.accordion__text {
  /* font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 12px; */
  padding: 18px;
}

.data-section {
  background-color: var(--neutral-color-light);
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
}
